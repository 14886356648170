div.errors span.error {
  color: var(--ion-color-danger);
  font-size: 12px;
  display: block;
  padding-top: 0.375rem;
  padding-left: 1rem;

}

.background-error {
  background-color: #f2dede;
  border-radius: 10px;
}
