ion-button {
  --border-radius: 30px;
  --border-width: 1px;

  --background: transparent;
  --border-color: var(--ion-text-color);
  --color: var(--ion-text-color);

  width: 100%;
  text-transform: none;

  &.primary-button {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
    --border-width: 0;
  }

  &.option-button {
    height: 26px;
    font-size: 12px;
  }

  ion-icon[slot="start"] {
    position: absolute;
    left: 0;
  }

  ion-icon[slot="end"] {
    position: absolute;
    right: 0;
  }

}
