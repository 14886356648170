// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: theserif;
  src: url(../../assets/fonts/theserif/theserif-black-plain.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

/** Ionic CSS Variables **/
/** Theme for that's lease **/
.theme-LM2142 {

  --ion-font-family: 'Open Sans', sans-serif;

  /** primary **/
  --ion-color-primary: #FF8400;
  --ion-color-primary-rgb: 6, 47, 196;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #B0E1F3;
  --ion-color-primary-tint: #ff901a;

  /** secondary **/
  --ion-color-secondary: #B0E1F3;
  --ion-color-secondary-rgb: 6, 47, 196;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #B0E1F3;
  --ion-color-secondary-tint: #3A5BD1;


  /** tertiary **/
  --ion-color-tertiary: #1A36A3;
  --ion-color-tertiary-rgb: 26, 54, 163;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #B0E1F3;
  --ion-color-tertiary-tint: #344FB9;

  /** success **/
  --ion-color-success: #008849;
  --ion-color-success-rgb: 0, 136, 73;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #006A39;
  --ion-color-success-tint: #1AA564;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #DC212A;
  --ion-color-danger-rgb: 220, 33, 42, 1;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #F23F48;
  --ion-color-danger-tint: #DC212A;

  /** dark **/
  --ion-color-dark: #132644;
  --ion-color-dark-rgb: 19, 38, 68;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #132644;
  --ion-color-dark-tint: #132644;

  /** medium **/
  --ion-color-medium: #99A9B8;
  --ion-color-medium-rgb: 153, 169, 184;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #99A9B8;
  --ion-color-medium-tint: #99A9B8;

  /** light **/
  --ion-color-light: #C8D2E0;
  --ion-color-light-rgb: 200, 210, 224;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #C8D2E0;
  --ion-color-light-tint: #C8D2E0;

  --msm-footer-background-color: white;
  --msm-getting-started-page-background-color: transparent;
  --msm-getting-started-page-color: #000000;
  --msm-getting-started-page-primary-button-border-width: 0;

  --msm-logo-display: block;
  --msm-logo-location: url(https://thatslease.nl/wp-content/uploads/2020/10/cropped-thats-lease-logo-02-1-1.png);
  --msm-logo-getting-started: url(https://thatslease.nl/wp-content/uploads/2020/10/cropped-thats-lease-logo-02-1-1-300x185.png);
  --msm-logo-height: 5rem;

  --msm-question-input-margin: 2rem;
  --msm-question-option-title-font-size: 1rem;
  --msm-summary-highlight-color: #E8E05DFF;
  --msm-question-boolean-border-radius: 5px;

  /* Solid buttons */
  ion-button {
    --border-radius:5px;
    --font-size: 15px;
    --margin-bottom:0;
    --margin-top:0;
    --padding: 15px;
    --border: 1px solid;
    --border-color: var(--ion-color-primary-shade);

    &.primary-button {
      --border-color: var(--ion-color-secondary);
    }

    &.primary-button:hover {
      --background-hover: var(--ion-color-secondary);
      --background-hover-opacity: 100%;

    }

    &.option-button {
      height: 50px;
    }
  }

}
