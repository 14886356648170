// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: Zonapro;
  src: url(../../assets/fonts/zonapro/zonapro-regular-webfont.woff) format('woff'),
    url(../../assets/fonts/zonapro/zonapro-regular-webfont.woff2) format('woff2');
}

/** Ionic CSS Variables **/
.theme-ohra {

  --ion-font-family: Zonapro;

  /** primary **/
  --ion-color-primary: #062FC4;
  --ion-color-primary-rgb: 6, 47, 196;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #002198;
  --ion-color-primary-tint: #3A5BD1;

  /** secondary **/
  --ion-color-secondary: #062FC4;
  --ion-color-secondary-rgb: 6, 47, 196;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #002198;
  --ion-color-secondary-tint: #3A5BD1;


  /** tertiary **/
  --ion-color-tertiary: #1A36A3;
  --ion-color-tertiary-rgb: 26, 54, 163;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #002198;
  --ion-color-tertiary-tint: #344FB9;

  /** success **/
  --ion-color-success: #008849;
  --ion-color-success-rgb: 0, 136, 73;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #006A39;
  --ion-color-success-tint: #1AA564;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #DC212A;
  --ion-color-danger-rgb: 220, 33, 42, 1;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #F23F48;
  --ion-color-danger-tint: #DC212A;

  /** dark **/
  --ion-color-dark: #132644;
  --ion-color-dark-rgb: 19, 38, 68;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #132644;
  --ion-color-dark-tint: #132644;

  /** medium **/
  --ion-color-medium: #99A9B8;
  --ion-color-medium-rgb: 153, 169, 184;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #99A9B8;
  --ion-color-medium-tint: #99A9B8;

  /** light **/
  --ion-color-light: #C8D2E0;
  --ion-color-light-rgb: 200, 210, 224;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #C8D2E0;
  --ion-color-light-tint: #C8D2E0;

  --msm-footer-background-color: white;
  --msm-getting-started-page-background-color: #132644;
  --msm-getting-started-page-color: white;
  --msm-getting-started-page-primary-button-border-width: 0;

  --msm-logo-display: block;
  --msm-logo-location: url(https://www.ohra.nl/assets/images/logo-ohra-email-280x88.png);
  --msm-logo-getting-started: url(https://www.ohra.nl/assets/images/logo-ohra-email-darkmode-280x88.png);
  --msm-logo-height: 5rem;

  --msm-question-input-margin: 2rem;

  --msm-question-option-title-font-size: 16px;

  --msm-summary-highlight-color: #E8E05DFF;
}
