@import 'src/theme/_variables';

.msm-page-container {
  margin-left: auto;
  margin-right: auto;

  .msm-logo {
    display: var(--msm-logo-display);
    margin-top: 1rem;
    height: var(--msm-logo-height);

    .msm-logo-holder {
      background-image: var(--msm-logo-location);
      background-repeat: no-repeat;
      background-size: contain;
      height: var(--msm-logo-height);
      width: 100%;
      background-position: center;
    }

    .msm-logo-holder-getting-started {
      @extend .msm-logo-holder;
      background-image: var(--msm-logo-getting-started);
    }

  }

}

@media (min-width: $screen-md-min) {
  .msm-page-container{
    width: 75%;
  }

  .msm-page-header ion-toolbar,
  .msm-page-footer {
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
}

@media (min-width: $screen-lg-min) {
  .msm-page-container {
    width: 60%;
  }

  .msm-page-header ion-toolbar,
  .msm-page-footer {
    padding-left: 20%;
    padding-right: 20%;
  }
}
