.theme-LM2131 {

  /** primary **/
  --ion-color-primary: #22a636;
  --ion-color-primary-rgb: 34, 166, 54;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #22a636;
  --ion-color-primary-tint:  #ffffff;;

  /** secondary **/
  --ion-color-secondary: #22a636;
  --ion-color-secondary-rgb: 34, 166, 54;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #22a636;
  --ion-color-secondary-tint:  #ffffff;

  --msm-getting-started-page-background-color: tranparent;
  --msm-getting-started-page-primary-button-border-width: 0;
  --msm-logo-display: block;
  --msm-logo-location: url(https://www.frieslandlease.nl/wp-content/uploads/2023/03/logo-friesland-lease.svg);
  --msm-logo-getting-started: url(https://www.frieslandlease.nl/wp-content/uploads/2023/03/logo-friesland-lease.svg);

  /* Solid buttons */
  ion-button {
    &.primary-button {
      --border-color: var(--ion-color-secondary);
    }

    &.option-button {
      --color: var(--ion-color-primary);
      --border-color: var(--ion-color-secondary);
    }
  }

}
