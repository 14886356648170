.theme-skindemo {
  --ion-toolbar-color: green;

  --ion-font-family: "Comic Sans MS", "Comic Sans", cursive;

  --ion-color-primary: #69bb7b;
  --ion-color-primary-rgb: 105,187,123;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #5ca56c;
  --ion-color-primary-tint: #78c288;

  --msm-footer-background-color: lightblue;
  --msm-getting-started-page-background-color: coral;
  --msm-getting-started-page-color: darkblue;

  --msm-logo-display: block;
  --msm-logo-location: url(../../assets/images/custom/skindemo/logo.png);
  --msm-logo-getting-started: url(../../assets/images/custom/skindemo/logo.png);
  --msm-logo-height: 100px;
  --msm-question-boolean-border-radius:100px;
}
