.theme-allianz {
  --ion-color-primary: #003781;
  --ion-color-primary-rgb: 0, 55, 129;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #007AB3;
  --ion-color-primary-tint: #007AB3;

  --ion-color-secondary: #007AB3;
  --ion-color-secondary-rgb: 22, 126, 213;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #007AB3;
  --ion-color-secondary-tint: #007AB3;

  --ion-color-tertiary: #007AB3;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #007AB3;
  --ion-color-tertiary-tint: #007AB3;

  --ion-color-success: #007AB3;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #007AB3;
  --ion-color-success-tint: #007AB3;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #007AB3;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #007AB3;
  --ion-color-medium-tint: #007AB3;

  --ion-color-light: #007AB3;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #007AB3;
  --ion-color-light-tint: #222428;

  --ion-text-color: #414141;

  --msm-footer-background-color: #Ffffff;
  --msm-getting-started-page-background-color: #F8F7F2;
  --msm-getting-started-page-color: #F8F7F2;
  --msm-getting-started-page-primary-button-border-width: 0;
  --msm-logo-display: block;
  --msm-logo-location: url(https://www.allianz.com/content/dam/onemarketing/system/allianz-logo.svg);
  --msm-logo-getting-started: url(https://www.allianz.com/content/dam/onemarketing/system/allianz-logo.svg);
  --msm-logo-height: 5rem;
  --msm-question-input-margin: 2rem;
  --msm-question-option-title-font-size: .875rem;
  --msm-summary-highlight-color: #E8E05DFF;

  .option-button {
    height: 2rem;
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-secondary-contrast);
  }

  ion-button.button-schade {
    background: var(--ion-color-secondary);
  }
}
