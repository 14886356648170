ion-item {
  font-size: var(--msm-ion-item-font-size);
}

.msm-required-question:not(.msm-parent-question) {
  ion-item.item-input,
  msm-boolean-input > ion-item.item-label,
  msm-select-input > ion-item.item-label,
  ion-item.msm-damage-indicator-question-container {
    & > ion-label::after {
      content: " *";
    }
  }
}

.msm-required-question:not(.msm-parent-question) {
  ion-textarea{
    .label-text::after {
      content: " *";
    }
  }
}

[hidden] {
  display: none !important;
}
