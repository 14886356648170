.theme-kia {

  $kia-midnight-black: #05141f;
  $kia-midnight-black-rgb: 5, 20, 31;
  $kia-polar-white: #fff;
  $kia-polar-white-rgb: 255,255,255;

  $kia-afternoon-yellow: #f3c300;
  $kia-afternoon-yellow-rgb: 243, 195, 0;
  $kia-forest-green: #5d7d2b;
  $kia-forest-green-rgb: 92, 125, 43;
  $kia-city-gray: #9ea1a2;
  $kia-city-gray-rgb: 158, 162, 162;

  /** primary **/
  --ion-color-primary: #{$kia-midnight-black};
  --ion-color-primary-rgb: #{$kia-midnight-black-rgb};
  --ion-color-primary-contrast: #{$kia-polar-white};
  --ion-color-primary-contrast-rgb: #{$kia-polar-white-rgb};
  --ion-color-primary-shade: #04121b;
  --ion-color-primary-tint: #1e2c35;

  /** secondary **/
  --ion-color-secondary: #{$kia-afternoon-yellow};
  --ion-color-secondary-rgb: #{$kia-afternoon-yellow-rgb};
  --ion-color-secondary-contrast: #{$kia-midnight-black};
  --ion-color-secondary-contrast-rgb: #{$kia-midnight-black-rgb};
  --ion-color-secondary-shade: #d6ac00;
  --ion-color-secondary-tint: #f4c91a;

  /** tertiary **/
  --ion-color-tertiary: #{$kia-city-gray};
  --ion-color-tertiary-rgb: #{$kia-city-gray-rgb};
  --ion-color-tertiary-contrast: #{$kia-midnight-black};
  --ion-color-tertiary-contrast-rgb: #{$kia-midnight-black-rgb};
  --ion-color-tertiary-shade: #8b8e8f;
  --ion-color-tertiary-tint: #a8aaab;

  --msm-getting-started-page-primary-button-border-width: 1px;
}
